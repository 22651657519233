import Wave from 'react-wavify'

import logo from '../images/logo_l.png';
import classes from './Intro.module.css'
import image from '../images/rdm.png'

const Intro = () => {
  return (
    <div id="intro" className={`${classes.main} h-screen relative`}>
      <div className={`hidden lg:flex absolute w-screen h-28 justify-between items-center px-20 z-20`}>
        <img className="lg:h-1/2 xl:h-2/3 py-4" src={logo} alt="DRIP OR DROWN"></img>
        <div className="lg:w-1/2 xl:w-2/5">
          <ul className="flex justify-around lg:text-xl xl:text-2xl">
            <a href="#intro"><li className={classes.li}>About us</li></a>
            <a href="#mint"><li className={classes.li}>Mint</li></a>
            <a href="#roadmap"><li className={classes.li}>Roadmap</li></a>
            <a href="#faq"><li className={classes.li}>FAQ</li></a>
          </ul>
        </div>
      </div>
      <div className="flex w-screen justify-center items-center h-full" style={{ /* height: 'calc(100% - 7rem)' */ }}>
        <div className="relative flex flex-col justify-around h-full py-6 lg:py-0 lg:justify-center items-center w-4/5 lg:w-3/5">
          <img className="lg:hidden lg:h-1/2 xl:h-2/3" src={logo} alt="DRIP OR DROWN"></img>
          <img src={image} alt="LEGENDARY" className="filter drop-shadow-2xl lg:mb-10 xl:mb-16 w-3/5 md:w-4/12 lg:w-1/5 xl:w-2/12 2xl:w-1/5"/>
          <div className="relative text-center">
            <h2 className="text-xl md:text-4xl lg:text-3xl xl:text-5xl 2xl:text-6xl pb-1 font-thicc-grunge text-main">Drip or Drown Pod Club</h2>
            <div className="bg-main w-full absolute bottom-0 h-1"></div>
          </div>
          <p className="lg:my-10 xl:my-16 text-center md:text-xl lg:text-lg xl:text-xl 2xl:text-2xl">Drip or Drown is a deflationary collection of 1567 NFTs that are randomly composed from 182 hand drawn traits are generated by a clever algorithm on the Solana network and will be fairly distributed to some very lucky minters.
            Each dolphin is completely unique, with no duplicates. All their different attributes serve a purpose in the quest of Atlantis.
          </p>
          <div>
            <a href="https://twitter.com/DriporDrownNFT"><button className="py-3 px-6 md:text-xl xl:text-2xl filter drop-shadow-xl rounded-full mr-6  bg-secondary hover:bg-main transition-all duration-300">Twitter</button></a>
            <a href="https://discord.gg/JUUNaucScf"><button className="py-3 px-6 md:text-xl xl:text-2xl filter drop-shadow-xl rounded-full ml-6  bg-secondary hover:bg-main transition-all duration-300">Discord</button></a>
          </div>
        </div>
      </div>
      <Wave className="w-full absolute bottom-0 -z-1"
            fill='#4EE0CE'
            paused={false}
            options={{
              height: 20,
              amplitude: 70,
              speed: 0.15,
              points: 5
            }}
      />
    </div>
  )
}

export default Intro