import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faDiscord, faMedium } from '@fortawesome/free-brands-svg-icons'

import crab from '../images/crab.png'

const Footer = () => {
  return (
    <div className="w-full h-32 bg-gradient-to-b from-dark-bg to-sand flex justify-between items-end py-8 px-10 lg:px-20 relative">
      <div className="hidden absolute h-3/4  filter brightness-0 left-0 opacity-80 top-0 w-full lg:flex justify-between px-28 transform -translate-y-1/2">
        <img src={crab} alt="crab"/>
        <img src={crab} alt="crab"/>
      </div>
      <span className="text-base lg:text-xl">&copy; Dripordrown</span>
      <div className="text-base space-x-6 lg:text-3xl lg:space-x-8">
        <a href="https://twitter.com/DriporDrownNFT">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="https://discord.gg/JUUNaucScf">
          <FontAwesomeIcon icon={faDiscord} />
        </a>
        <a href="#">
          <FontAwesomeIcon icon={faMedium} />
        </a>
      </div>
    </div>
  )
}

export default Footer