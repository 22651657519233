import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import ReCAPTCHA from "react-google-recaptcha";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from "./candy-machine";

const api_url = process.env.REACT_APP_API_URL

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isCaptchaValid, setIsCaptchaValid] = useState(false); // true when countdown completes
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(true); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [isWhitelisted, SetWhitelisted] = useState(false);

  const [itemsAvailable, setItemsAvailable] = useState(1567);
  const [itemsRedeemed, setItemsRedeemed] = useState(1567);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();
  
  console.log(itemsRemaining)
  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );
      console.log(balance)
      console.log(itemsAvailable)
      console.log(itemsRedeemed)
      setItemsAvailable(1567); //itemsAvailable
      setItemsRemaining(0); //itemsRemaining
      setItemsRedeemed(1567); //itemsRedeemed

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const captchaChange = (value: any) => {
    console.log(value)
    setIsCaptchaValid(prevCaptcha => !prevCaptcha)
  }

  const onMint = async () => {
    if (itemsRemaining === 0) {
      return;
    }
    try {
      /* if (!isCaptchaValid) {
        console.log('invalid captcha')
        throw new Error(`Invalid captcha.`)
      } */
      let res_num = 0
      if (Date.now() < 1636326000000) {
        let res = await fetch(`${api_url}/whitelisted/member/${(wallet as anchor.Wallet).publicKey.toString()}`, { method: "GET" })
        if(!isWhitelisted || res.status === 404){
          throw new Error(`You are not whitelisted.`)
        }
        const res_json = await res.json()
        res_num = await JSON.parse(JSON.stringify(res_json)).reserve
        if(res_num - 1 < 0){
          console.log("confirmed")
          throw new Error(`You have already minted your allocated supply.`)
        }
      }
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
          if (Date.now() < 1636326000000) {
            const to_send = await JSON.stringify({ "reserve": res_num - 1 })
            await fetch(`${api_url}/whitelisted/update/${(wallet as anchor.Wallet).publicKey.toString()}/${process.env.REACT_APP_SECRET_KEY}`, {
              method: "PUT",
              headers: {
                'Content-Type': 'application/json',
              },
              body: to_send
            })
            console.log("Updated Reserves for user")
          }
        } else  {
          setAlertState({ 
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      console.log(error)
      // TODO: blech:
      let message = error.msg || error.message || "Minting failed! Please try again!";
      console.log(error.message)
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        } else if (error.message === `Invalid captcha.`) {
          message = error.message
        } else if (error.message === `You are not whitelisted.`){
          message = error.message
        } else if (error.message === `You have already minted your allocated supply.`){
          message = error.message
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        } else if (error.message === `Invalid captcha.`) {
          message = error.message
        } else if (error.message === `You are not whitelisted.`){
          message = error.message
        } else if (error.message === `You have already minted your allocated supply.`){
          message = error.message
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
        const data = await fetch(`${api_url}/whitelisted/member/${(wallet as anchor.Wallet).publicKey.toString()}`)
        if(data.status.toString() !== "404"){
          SetWhitelisted(true)
        }
        else{
          console.log("not found")
        }
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main className="w-full lg:w-3/5 flex flex-col justify-center items-center lg:pl-8 text-center lg:text-left">
      <span className="text-2xl lg:text-3xl 2xl:text-5xl">Join your pod now on their quest to Atlantis</span>
      <span className="text-xl lg:text-xl 2xl:text-3xl w-full">For the modest cost of 0.2 SOL</span>
      {/*wallet && (
        <p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
      )*/}

      <div className="w-full h-14 2xl:h-16 border-4 border-white rounded-full relative my-4 lg:my-8 overflow-hidden">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg lg:text-2xl">{itemsRemaining} / {itemsAvailable}</div>
        <div className="h-full bg-cyaney rounded-full" style={{width: `${(itemsRemaining) / itemsAvailable * 100}%`}}></div>
      </div>

      

      {/*wallet && <p>Balance: {(balance || 0).toLocaleString()} SOL</p>*/}

      <MintContainer>
        {!wallet ? (
          <button disabled={ isSoldOut } className="py-3 px-6 filter shadow-xl rounded-full w-full text-lg lg:text-2xl border-2 border-main bg-secondary font-thicc  cursor-not-allowed transition-all duration-300">Sold out</button>
        ) : (
            <>
          {/* <ReCAPTCHA
            sitekey="6LcXWe0cAAAAALvtagAuYb1apjh6ty0XTA6KJYl_"
            onChange={captchaChange}
          /> */}
          <button
            disabled={ isSoldOut || isMinting || !isActive}
            onClick={onMint}
            className="py-3 px-6 filter shadow-xl rounded-full w-full text-2xl border-2 border-main bg-secondary mt-6 font-thicc cursor-not-allowed transition-all duration-300"
          > 
            {isSoldOut ? (
              "SOLD OUT"
            ) : isActive ? (
              isMinting ? (
                <CircularProgress />
              ) : (
                "SOLD OUT"
              )
            ) : (
              <Countdown
                date={isWhitelisted ? startDate : new Date(1636326000000)}
                onMount={({ completed }) => completed && setIsActive(true)}
                onComplete={() => setIsActive(true)}
                renderer={renderCounter}
              />
            )}
          </button>
          </>
        )}
      </MintContainer>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
