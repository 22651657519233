import { useRef } from 'react'

import classes from './Roadmap.module.css'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import fish1 from '../images/fish-1.png'
import fish2 from '../images/fish-2.png'
import fish3 from '../images/fish-3.png'
import fish4 from '../images/fish-4.png'
import fish5 from '../images/fish-5.png'
import fish6 from '../images/fish-6.png'
import fish7 from '../images/fish-7.png'
import fish8 from '../images/fish-8.png'

const Roadmap = () => {
  const content = useRef<any>()

  let elements = [
    {
      title: 'Pod Welcoming',
      content: 'After our mint has concluded we will be listing on Magic Eden. We are in talks with Alpha Art and Digital eyes also. We will be honoured to welcome any new pod members who were not able to successfully mint and are successfully able to purchase from the secondary markets.',
      done: true
    },
    {
      title: 'Royalties',
      content: (<p>There will be a 6% Royalty Fee for secondary market sales which is divided into two parts.<br/><br/>- 2% For The Treasure Chest<br/>- 4% For The Team</p>),
      done: false
    },
    {
      title: 'Treasure Chest',
      content: '2% of the 6% from secondary sales will be used for the treasure chest where the fallen Dolphins ashes will be kept after being swept from the floor and burnt. We will be using a deflationary model and we will sweep the floor of the cheapest dolphins from time to time and burning these. The dolphins who sacrifice their life for the pod will be kept inside the treasure chest forever.',
      done: false
    },
    {
      title: '$SHELL (Q1 2022)',
      content: 'Drip or Drown owners will be allocated a certain amount of $SHELL tokens for each Drip or Drown NFT that they hold. The more Drip or Drown NFT’s that you hold, the more $SHELL tokens that you will receive. The $SHELL tokens can be used for staking, purchasing custom merchandise and voting powers inside of the DAO. They will also grant you exclusive access to future drops that we have planned as well as exclusive benefits regarding the animated series that we are working on.',
      done: false
    },
    {
      title: 'Animated Series (Q1 2022)',
      content: 'We will create an animated cartoon series which will feature the Drip or Drown NFT’s and will bring the Dolphin Lore to life on your screens! We plan to roll out around twelve, ten to twenty minute episodes initially. If you own any of the Drip or Drown NFT’s of the dolphins that make appearances inside of the series then you will be rewarded $SHELL tokens, you will also be entered into mega giveaways etc. The community members will have the opportunity to vote in how we do this!',
      done: false
    }
  ]

  return (
    <div ref={content} id="roadmap" className="w-screen mt-20 lg:mt-36 xl:mt-48 pt-12 py-60 relative flex flex-col justify-center items-center bg-gradient-to-b from-bg to-dark-bg">
      <img src={fish1} alt="" className="absolute opacity-30 hidden lg:block xl:left-1/8 transform -translate-x-1/2 scale-x-n-1 filter brightness-0" style={{top: '17.5%'}}/>
      <img src={fish2} alt="" className="absolute opacity-30 hidden lg:block xl:left-1/8 transform -translate-x-1/2 scale-x-n-1 filter brightness-0" style={{top: '36.66%'}}/>
      <img src={fish3} alt="" className="absolute opacity-30 hidden lg:block xl:left-1/8 transform -translate-x-1/2 scale-x-n-1 filter brightness-0" style={{top: '55.83%'}}/>
      <img src={fish7} alt="" className="absolute opacity-30 hidden lg:block xl:left-1/8 transform -translate-x-1/2 filter brightness-0" style={{top: '75%'}}/>
      <img src={fish4} alt="" className="absolute opacity-30 hidden lg:block xl:right-1/8 transform translate-x-1/2 filter brightness-0" style={{top: '25%'}}/>
      <img src={fish5} alt="" className="absolute opacity-30 hidden lg:block xl:right-1/8 transform translate-x-1/2 scale-x-n-1 filter brightness-0" style={{top: '44.17%'}}/>
      <img src={fish6} alt="" className="absolute opacity-30 hidden lg:block xl:right-1/8 transform translate-x-1/2 filter brightness-0" style={{top: '63.33%'}}/>
      <img src={fish8} alt="" className="absolute opacity-30 hidden lg:block xl:right-1/8 transform translate-x-1/2 filter brightness-0" style={{top: '82.5%'}} />
       {/* <img src={fish1} className="absolute opacity-30 hidden lg:block" style={{top: '27%', left: '5%', filter: 'brightness(0%)'}}/>
      <img src={fish2} className="absolute opacity-30 hidden lg:block" style={{top: '48%', left: '15%', filter: 'brightness(0%)'}}/>
      <img src={fish3} className="absolute opacity-30 hidden lg:block" style={{top: '69%', left: '9%', filter: 'brightness(0%)'}}/>
      <img src={fish7} className="absolute opacity-30 hidden lg:block" style={{top: '87%', left: '12%', filter: 'brightness(0%)'}}/>
      <img src={fish4} className="absolute opacity-30 hidden lg:block" style={{top: '34%', right: '10%', transform: 'rotate(-135deg) scaleX(-1)', filter: 'brightness(0%)'}}/>
      <img src={fish5} className="absolute opacity-30 hidden lg:block" style={{top: '58%', right: '3%', transform: 'scaleX(-1)', filter: 'brightness(0%)'}}/>
      <img src={fish6} className="absolute opacity-30 hidden lg:block" style={{top: '85%', right: '15%', transform: 'scaleX(-1)', filter: 'brightness(0%)'}}/>
      <img src={fish8} className="absolute opacity-30 hidden lg:block" style={{ top: '74%', right: '8%', transform: 'rotate(9deg)', filter: 'brightness(0%)'}}/> */}
      {/* <img src={fish1} className="absolute opacity-70 hidden lg:block" style={{top: '27%', left: '5%',}}/>
      <img src={fish2} className="absolute opacity-50 hidden lg:block" style={{top: '48%', left: '15%'}}/>
      <img src={fish3} className="absolute opacity-20 hidden lg:block" style={{top: '69%', left: '9%'}}/>
      <img src={fish7} className="absolute opacity-10 hidden lg:block" style={{top: '87%', left: '12%'}}/>
      <img src={fish4} className="absolute opacity-60 hidden lg:block" style={{top: '34%', right: '10%', transform: 'rotate(-135deg) scaleX(-1)'}}/>
      <img src={fish5} className="absolute opacity-30 hidden lg:block" style={{top: '58%', right: '3%', transform: 'scaleX(-1)'}}/>
      <img src={fish6} className="absolute opacity-10 hidden lg:block" style={{top: '85%', right: '15%', transform: 'scaleX(-1)'}}/>
      <img src={fish8} className="absolute opacity-20 hidden lg:block" style={{ top: '74%', right: '8%', transform: 'rotate(9deg)' }}/> */}
      <div className="relative flex flex-col justify-center items-center w-11/12 md:w-4/5 xl:w-3/5 pb-12">
        <div className="relative text-center">
          <h2 className="text-4xl lg:text-6xl pb-1 font-thicc-grunge text-main">Roadmap</h2>
          <div className="bg-main w-full absolute bottom-0 h-1"></div>
        </div>
        <p className="my-16 text-center text-lg lg:text-2xl">
          By minting a Drip or Drown dolphin you will be welcomed into the official Drip or Drown pod! The pod is focused only on the members inside of the pod and their fellow marine friends out in the oceans. 
          <br /><br />
          We are all one big family and our goal as a pod is simple, lookout for one another and come together to have a positive impact to help change the world, both the ‘virtual world’ and the ‘real world’. 
          <br /><br />
          Just remember, there can only be a maximum of 1567 pod members…
        </p>
      </div>
      <div className={`${classes.main} w-11/12 md:w-2/3 xl:w-1/2 h-4/5 flex flex-col justify-center items-center`}>
        {elements.map((el, index) => (
          <div className="flex justify-between items-center w-full my-6" key={index}>
            <div className={`${classes.line} h-full relative flex justify-center items-center z-10`}>
              <div className={`${el.done ? 'bg-cyaney' : 'bg-secondary border border-white'} w-8 h-8  rounded-full filter shadow-xl`}></div>
            </div>
            <div className="w-3/4 flex flex-col border-2 border-cyaney rounded-xl p-4">
              <div className="text-thicc text-main text-2xl">{el.title}</div>
              {el.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Roadmap
