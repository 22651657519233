import { useState, useEffect } from 'react'

import Intro from './pages/Intro'
import Slider from './pages/Slider'
import Mint from './pages/Mint'
import Roadmap from './pages/Roadmap'
import Faq from './pages/Faq'
import Footer from './pages/Footer'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

import * as anchor from "@project-serum/anchor";

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Test = (props: HomeProps) => {
  const [showTopButton, setShowTopButton] = useState(false);

  const scrollUp = () => {
    window.scrollTo(0, 0)
  }

  useEffect(
    () => {
      console.log('ran')
      const onScroll = () => setShowTopButton(window.scrollY > window.outerHeight && (window.innerHeight + window.scrollY) !== document.body.offsetHeight ? true : false)
      window.addEventListener("scroll", onScroll);
      
      return () => window.removeEventListener("scroll", onScroll);
    },
    [showTopButton]
  )

  return (
    <div className="relative">
      <Intro />
      <Slider />
      <Mint
        candyMachineId={props.candyMachineId}
        config={props.config}
        connection={props.connection}
        startDate={props.startDate}
        treasury={props.treasury}
        txTimeout={props.txTimeout}
      />
      <Roadmap />
      <Faq />
      <Footer />
      <button onClick={scrollUp} className={`${showTopButton ? '' : 'opacity-0'} w-12 h-12 bg-main rounded-xl fixed right-8 bottom-8 flex justify-center items-center text-3xl transition-all duration-300`}><FontAwesomeIcon icon={faAngleUp} /></button>
    </div>
  )
}

export default Test