import { useState, useEffect, useRef} from 'react'

import classes from './Slider.module.css'
import img from '../images/randomDolphin.jpg'

import dolph1 from '../images/release1_0603.png';
import dolph2 from '../images/release2_3484.png';
import dolph3 from '../images/release3_1430.png';
import dolph4 from '../images/release4_0000.png';
import dolph5 from '../images/release5_0000.png';
import dolph6 from '../images/release6_0000.png';
import dolph7 from '../images/release7_0000.png';
import dolph8 from '../images/release8_2432.png';

const Slider = () => {
  const image = useRef<any>(null)
  let data = [
    {
      img: dolph1,
      date: '10/29/21',
      id: '0603',
      name: 'Corbett'
    },
    {
      img: dolph2,
      date: '10/29/21',
      id: '3484',
      name: 'Quinto'
    },
    {
      img: dolph3,
      date: '10/31/21',
      id: '1430',
      name: 'Hawser'
    },
    {
      img: dolph4,
      date: '11/01/21',
      id: '2575',
      name: 'Gus'
    },
    {
      img: dolph5,
      date: '11/02/21',
      id: '1712',
      name: 'Rider'
    },
    {
      img: dolph6,
      date: '11/03/21',
      id: '643',
      name: 'Tylos'
    },
    {
      img: dolph7,
      date: '11/04/21',
      id: '752',
      name: 'Mokoro'
    },
    {
      img: dolph8,
      date: '11/05/21',
      id: '2432',
      name: 'Chance'
    },
    {
      img: img,
      date: '11/09/21',
      id: '0000',
      name: 'Dolphin'
    },
    {
      img: img,
      date: '11/10/21',
      id: '0000',
      name: 'Dolphin'
    },
  ]
  
  let temp = []
  for (let i = 0; i < 10; i++) {
    temp.push(
      <div ref={image} className="inline-block w-1/3 lg:w-1/5 py-2 lg:py-4 px-2 text-center lg:text-xl" >
        <p>Revealed <br className="block lg:hidden"/>{data[i].date}</p>
        <img src={data[i].img} alt={`${i}`}/>
        <p>{data[i].name}  <br className="block lg:hidden"/>#{data[i].id}</p>
      </div>
    )
  }
  
  const [position, setPosition] = useState(0);
  const [index, setIndex] = useState(0);
  const [slides, setSlides] = useState<any>(temp);

  useEffect(
    () => {
      let interval = setInterval(() => {
        setIndex(prevIndex => prevIndex += 1)
        // @ts-ignore: Object is possibly 'null'.
        if (image && image.current && image.current.offsetWidth) setPosition(prevPosition => prevPosition += image.current?.offsetWidth)
        setSlides((prevSlides: any[]) => [...prevSlides, prevSlides[index]])
      }, 2000)

      return function cleanup() {
        clearInterval(interval);
      };
    },
    [position]
  );

  return (
    <div className={`${classes.main} relative w-screen bg-main filter shadow-2xl overflow-x-hidden`}>
      <div className="whitespace-nowrap w-full transition-all duration-300 px-2" style={{ transform: `translateX(-${position}px)`}}>
        { slides }
      </div>
    </div>
  )
}

export default Slider