import classes from './Faq.module.css'

  const qs = [
    {
      q: 'Are there any benefits of becoming a Drip or Drown Club member?',
      a: 'Owning a Drip or Drown Dolphin gives you exclusive access to the pod as well as the ability to be airdropped $SHELL tokens.'
    },
    {
      q: 'How many drip or drown dolphins will there be available to mint?',
      a: '1567'
    },
    {
      q: 'How much will it cost to mint one Drip or Drown dolphin?',
      a: '0.2 SOL'
    },
    {
      q: 'When will I be able to mint my Drip or Drown dolphin?',
      a: '7th November'
    },
    {
      q: 'Will there be a whitelist event?',
      a: `Yes. Check our discord! - https://discord.gg/JUUNaucScf`
    },
    {
      q: 'What blockchain will you be using?',
      a: 'Solana'
    },
    {
      q: 'How many mints per transaction?',
      a: '1 per transaction but no limit on mints per wallet'
    },
    {
      q: 'What secondary sales market will there be?',
      a: 'To be confirmed'
    },
    {
      q: 'When can we trade Drip or Drown after launch?',
      a: 'Immediately after'
    },
    {
      q: 'Do I own my Drip or Drown dolphin after purchasing it?',
      a: 'Full intellectual property rights are granted to the buyer'
    }
  ]

const Faq = () => {
  return (
    <div id="faq" className="w-screen flex justify-center items-center bg-dark-bg pb-20">
      <div className="w-4/5 xl:w-3/5">
        <div className="text-center">
          <h2 className={`${classes.title} inline-block relative text-main text-6xl pb-1 font-thicc-grunge mb-14`}>FAQ</h2>
        </div>
        <div className="flex flex-wrap">
          <div className="flex flex-col flex-wrap flex-1 md:mr-2">
            {qs.slice(0, 5).map(({ q, a }, i) => {
              return (
                <div key={i} className="bg-gradient-to-br from-dark-bg to-bg filter shadow-2xl rounded-2xl p-6 my-2 pl-12 relative">
                  <span className="absolute top-6 left-6 text-main text-xl">{i + 1}.</span>
                  <h4 className="text-main text-xl">{q}</h4>
                  <p>{a}</p>
                </div>
              )
            })}
          </div>
          <div className="flex flex-col flex-wrap flex-1 md:ml-2">
            {qs.slice(5, 10).map(({ q, a }, i) => {
              return (
                <div key={i+5} className="bg-gradient-to-br from-dark-bg to-bg filter shadow-2xl rounded-2xl p-6 my-2 pl-12 relative">
                  <span className="absolute top-6 left-6 text-main text-xl">{i + 6}.</span>
                  <h4 className="text-main text-xl">{q}</h4>
                  <p>{a}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq