import image from '../images/RamdomDolphins.png';
import Home from '../Home'

import * as anchor from "@project-serum/anchor";

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}


const Mint = (props: HomeProps) => {
  return (
    <div id="mint" className="h-screen w-screen flex justify-center items-center">
      <div className="w-11/12 md:w-3/5 lg:w-4/5 xl:w-3/5 p-1 bg-gradient-to-b from-main to-cyaney rounded-3xl">
        <div className="w-full filter bg-bg shadow-2xl rounded-3xl px-8 py-8 flex flex-col lg:flex-row justify-between items-center">
          <div className="inline-flex flex-col h-full w-11/12 md:w-3/5 lg:w-2/5 justify-center items-center text-3xl pb-4 lg:pb-0">
            <img src={image} alt="Random Dolphin" className="rounded-lg"/>
          </div>
          <Home
            candyMachineId={props.candyMachineId}
            config={props.config}
            connection={props.connection}
            startDate={props.startDate}
            treasury={props.treasury}
            txTimeout={props.txTimeout}
          />
        </div>
      </div>
    </div>
  )
}

export default Mint;